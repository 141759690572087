import React, { useState } from 'react';
import { TextField, Button, Stack } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CustomSelect from '../components/CustomSelect';

const getCurrentDateTime = () => {
    const now = new Date();
    return now;
};

function AddSession({
    initialDate = getCurrentDateTime(),
    initialDuration = '',
    initialFocus = '',
    initialPerformance = '',
    initialFun = '',
    initialRetro = '',
    performanceOptions = ['awful', 'weak', 'medium', 'high', 'outstanding'],
    funOptions = ['boring', 'dull', 'average', 'fun', 'exciting'],
    typeOptions = [],
    initialType = ''
}) {
    const [formData, setFormData] = useState({
        date: initialDate,
        duration: initialDuration,
        focus: initialFocus,
        performance: initialPerformance,
        fun: initialFun,
        retro: initialRetro,
        type: initialType
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
        console.log(`${name}: ${value}`);
    };

    const handleDateChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value
        });
        console.log(`${name}: ${value}`);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <form onSubmit={handleSubmit}>
                <Stack gap={2}>
                    <Stack direction={'row'} gap={2} sx={{ marginTop: 2 }}>
                        <DateTimePicker
                            label="Date and time"
                            value={formData.date}
                            onChange={(newValue) => handleDateChange('date', newValue)}
                            format="MMM dd, yyyy | HH:mm"
                            viewRenderers={window.innerWidth > 768 ? {
                                hours: null,
                                minutes: null,
                                seconds: null,
                            } : {}}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                />
                            )}
                            ampm={false}
                            sx={{
                                flex: 1.25
                            }}
                        />
                        <TextField
                            label="Duration (m)"
                            type="number"
                            name="duration"
                            value={formData.duration}
                            onChange={handleChange}
                            fullWidth
                            sx={{ flex: 1 }}
                        />
                    </Stack>
                    <CustomSelect
                        initialOptions={typeOptions}
                        label={'Type'}
                        defaultValue={formData.type}
                        onChange={(value) => handleChange({ target: { name: 'type', value } })}
                    />
                    <TextField
                        label="Focus"
                        name="focus"
                        value={formData.focus}
                        onChange={handleChange}
                        fullWidth
                        multiline
                        rows={4}
                    />
                    <CustomSelect
                        initialOptions={performanceOptions}
                        label={'Performance'}
                        defaultValue={formData.performance}
                        onChange={(value) => handleChange({ target: { name: 'performance', value } })}
                    />
                    <CustomSelect
                        initialOptions={funOptions}
                        label={'Fun'}
                        defaultValue={formData.fun}
                        onChange={(value) => handleChange({ target: { name: 'fun', value } })}
                    />
                    <TextField
                        label="Retro"
                        name="retro"
                        value={formData.retro}
                        onChange={handleChange}
                        fullWidth
                        multiline
                        rows={4}
                    />
                    <Button type="submit" variant="contained" color="primary">
                        Submit
                    </Button>
                </Stack>
            </form>
        </LocalizationProvider>
    );
}

export default AddSession;