import React, { useState } from 'react';
import { TextField, Button, Stack, Typography, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import { Link } from 'react-router-dom';

function AddTrack() {
    const [formData, setFormData] = useState({
        trackName: '',
        trackDescription: '',
        showUpGoal: '',
        timeUnit: 'minutes',
        frequency: 'daily'
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission
    };

    return (
        <form onSubmit={handleSubmit}>
            <Stack gap={2} sx={{marginTop: 1}}>
                <TextField
                    label="Name"
                    name="Name"
                    value={formData.trackName}
                    onChange={handleChange}
                    fullWidth
                />
                <TextField
                    label="Description"
                    name="Description"
                    value={formData.trackDescription}
                    onChange={handleChange}
                    fullWidth
                    multiline
                    rows={4}
                />
                <Typography variant="h8" sx={{fontWeight: 'bold'}}>Show up goal</Typography>
                <Stack direction={'row'} spacing={2}>
                    <TextField
                        label="Time"
                        name="Time"
                        type="number"
                        value={formData.showUpGoal}
                        onChange={handleChange}
                        sx={{ flex: 1 }}
                    />
                    <FormControl sx={{ flex: 1 }}>
                        <InputLabel>Unit</InputLabel>
                        <Select
                            label="Unit"
                            name="Unit"
                            value={formData.timeUnit}
                            onChange={handleChange}
                        >
                            <MenuItem value="minutes">Minutes</MenuItem>
                            <MenuItem value="hours">Hours</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl sx={{ flex: 1 }}>
                        <InputLabel>When</InputLabel>
                        <Select
                            label="When"
                            name="When"
                            value={formData.frequency}
                            onChange={handleChange}
                        >
                            <MenuItem value="daily">Daily</MenuItem>
                            <MenuItem value="every 7 days">Every 7 days</MenuItem>
                            <MenuItem value="every 30 days">Every 30 days</MenuItem>
                        </Select>
                    </FormControl>
                </Stack>

                <Typography variant="body2">
                    Set how much time you want to spend on this activity. This will be your 100% personal activity index (PAI). PAI shows how consistent you are. Read You can read how and why PAI works <Link to="/manifest" target="_blank">here</Link>.
                </Typography>
                <Button type="submit" variant="contained" color="primary">
                    Create
                </Button>
            </Stack>
        </form>
    );
}

export default AddTrack;
