import React from 'react';
import { Outlet } from 'react-router-dom';
import { Container, Box } from '@mui/material';
import Header from './components/Header';
import { useTheme } from '@mui/material/styles';

function App() {
  const theme = useTheme();

  return (
    <Box sx={{ backgroundColor: theme.palette.background.default, minHeight: '100vh' }}>
      <Container>
        <Header />
        <Outlet />
      </Container>
    </Box>
  );
}

export default App;