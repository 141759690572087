import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar, Button, IconButton, List, ListItem, ListItemAvatar, ListItemText, Stack, Dialog, DialogTitle, DialogContent, useMediaQuery, SwipeableDrawer, Box, Typography } from '@mui/material';
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add'; // Import AddIcon
import ActivityIndex from '../components/ActivityIndex';
import styled from 'styled-components';
import AddTrack from './AddTrack';
import { useTheme } from '@emotion/react';

const StyledListItem = styled(ListItem)`
  &:hover .icon-button {
    visibility: visible;
  }
`;

const StyledIconButton = styled(IconButton)`
  visibility: hidden;
`;

const Tracks = () => {
    const theme = useTheme();
    // need to receive goals from bd
    const [goals, setGoals] = useState([
        'Pet-projects', 'Analytics', 'Driving',
        'Pet-projects', 'Analytics', 'Driving',
        'Pet-projects', 'Analytics', 'Driving',
    ]);

    const [openDialog, setOpenDialog] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const isMobile = useMediaQuery('(max-width:600px)');
    let navigate = useNavigate(); 

    const stopProp = (e) =>{ 
        e.stopPropagation();
    }

    const handleAddTrackClick = () => {
        if (isMobile) {
            setOpenDrawer(true);
        } else {
            setOpenDialog(true);
        }
    };

    const handleClose = () => {
        setOpenDialog(false);
        setOpenDrawer(false);
    };

    return (
        <Stack>
            <List>
                {goals.map(el => (
                    <StyledListItem
                        button
                        onClick={() => navigate(`/tracks/${el.replace(' ', '_').toLowerCase()}`)}
                    >
                        <ListItemAvatar>
                            <Avatar sx={{ bgcolor: 'transparent' }}>
                                <ActivityIndex 
                                    size={40}
                                    color={"red"} 
                                    ringBgColor={"red"} 
                                    showText={false}
                                    progress={120}
                                />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={el}/>
                        <StyledIconButton onClick={stopProp} className="icon-button" title="Start session">
                            <PlayCircleFilledWhiteOutlinedIcon/>
                        </StyledIconButton>
                        <StyledIconButton onClick={stopProp} className="icon-button" title="Add session">
                            <AddCircleOutlineOutlinedIcon/>
                        </StyledIconButton>
                    </StyledListItem>
                ))}
            </List>
            <Box sx={{ height: 80 }} /> {/* Spacer element */}
            <Button 
                variant='contained' 
                onClick={() => {
                    handleAddTrackClick()
                    setGoals(
                        ['Pet-projects', 'Analytics', 'Driving',
                        'Pet-projects', 'Analytics', 'Driving',
                        'Pet-projects', 'Analytics', 'Driving', 'Test'])}}
                sx={{
                    position: 'fixed',
                    bottom: 16,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    zIndex: 1000,
                    background: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                    '&:hover': {
                        background: theme.palette.primary.dark,
                    },
                }}
                startIcon={<AddIcon />} // Add the AddIcon here
            >
                Add track
            </Button>
            <Dialog open={openDialog} onClose={handleClose} fullWidth maxWidth="sm">
                <DialogTitle>
                    Your new track
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <AddTrack/>
                </DialogContent>
            </Dialog>
            <SwipeableDrawer
                anchor="bottom"
                open={openDrawer}
                onClose={handleClose}
                onOpen={() => setOpenDrawer(true)}
                sx={{
                    '& .MuiPaper-root': {
                        borderTopLeftRadius: 16,
                        borderTopRightRadius: 16,
                        padding: 3,
                        paddingTop: 4
                    },
                }}
            >
                <Box
                    sx={{
                        width: 30,
                        height: 6,
                        backgroundColor: 'grey.300',
                        borderRadius: 3,
                        position: 'absolute',
                        top: 8,
                        left: 'calc(50% - 15px)',
                    }}
                />
                <Typography variant="h5" style={{fontWeight: 'bold'}}>New track</Typography>
                <Box sx={{marginTop: 1}}>
                    <AddTrack />
                </Box>
            </SwipeableDrawer>
        </Stack>
    );
};

export default Tracks;