import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Card, CardContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@emotion/react';

const tableCellStyle = { borderBottom: 'none' };
const columnLabels = {pb: 0.5, borderBottom: 'none'}

const SessionTable = ({ sessions }) => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const theme = useTheme();
  const navigate = useNavigate();

  const groupedSessions = sessions.reduce((acc, session) => {
    const date = new Date(session.dateTime).toLocaleDateString('en-GB', { year: 'numeric', month: 'long', day: 'numeric' });
    if (!acc[date]) acc[date] = [];
    acc[date].push(session);
    return acc;
  }, {});

  if (isMobile) {
    return (
      <Box>
        {Object.keys(groupedSessions).map(date => (
          <React.Fragment key={date}>
            <Typography style={{ marginLeft: theme.spacing(2), marginTop: theme.spacing(2), color: theme.palette.text.primary }}>{date}</Typography>
            {groupedSessions[date].map((session, index) => (
              <Card
                key={`session-${date}-${index}`}
                sx={{
                  backgroundColor: 'transparent',
                  border: `1px solid ${theme.palette.primary.main}`,
                  boxShadow: 'none',
                  marginTop: theme.spacing(1),
                  backgroundImage: 'none'
                }}
                onClick={() => navigate(`/list/${session.id}`)}
              >
                <CardContent style={{paddingBottom: theme.spacing(2)}}>
                  <Typography style={{ color: theme.palette.text.primary, marginBottom: theme.spacing(1) }}>{`Session at ${new Date(session.dateTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`}</Typography>
                  <Typography style={{ color: theme.palette.text.secondary }}>Duration: <Typography component="span" style={{ color: theme.palette.text.primary }}>{session.duration} min</Typography></Typography>
                  <Typography style={{ color: theme.palette.text.secondary }}>Performance: <Typography component="span" style={{ color: theme.palette.text.primary }}>{session.performance}</Typography></Typography>
                  <Typography style={{ color: theme.palette.text.secondary }}>Has Focus: <Typography component="span" style={{ color: theme.palette.text.primary }}>{session.focus ? 'Yes' : 'No'}</Typography></Typography>
                  <Typography style={{ color: theme.palette.text.secondary }}>Has Retro: <Typography component="span" style={{ color: theme.palette.text.primary }}>{session.retro ? 'Yes' : 'No'}</Typography></Typography>
                </CardContent>
              </Card>
            ))}
          </React.Fragment>
        ))}
      </Box>
    );
  }

  return (
    <TableContainer component={Box} style={{ width: 'auto', backgroundColor: 'transparent', display: 'flex', justifyContent: 'center' }}>
      <Table stickyHeader style={{ width: 'auto' }}>
        <TableHead>
          <TableRow>
            <TableCell sx={columnLabels}>DateTime</TableCell>
            <TableCell sx={columnLabels}>Duration(m)</TableCell>
            <TableCell sx={columnLabels}>Performance</TableCell>
            <TableCell sx={columnLabels}>Has Focus</TableCell>
            <TableCell sx={columnLabels}>Has Retro</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(groupedSessions).map(date => (
            <React.Fragment key={date}>
              <TableRow key={`date-${date}`}>
                <TableCell colSpan={5} style={{borderBottom: 'none', padding: theme.spacing(0), paddingTop: theme.spacing(1)}}>
                  <Typography style={{ padding: '16px', color: theme.palette.text.primary }}>{date}</Typography>
                </TableCell>
              </TableRow>
              {groupedSessions[date].map((session, index) => (
                <TableRow
                  key={`session-${date}-${session.id || index}`}
                  hover
                  style={{ textDecoration: 'none', cursor: 'pointer' }}
                  onClick={() => navigate(`/list/${session.id}`)}
                >
                  <TableCell style={tableCellStyle}>{new Date(session.dateTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</TableCell>
                  <TableCell style={tableCellStyle}>{session.duration}</TableCell>
                  <TableCell style={tableCellStyle}>{session.performance}</TableCell>
                  <TableCell style={tableCellStyle}>{session.focus ? 'Yes' : 'No'}</TableCell>
                  <TableCell style={tableCellStyle}>{session.retro ? 'Yes' : 'No'}</TableCell>
                </TableRow>
              ))}
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SessionTable;