import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Box, Typography, Slider, TextField, Dialog, DialogTitle, DialogContent, SwipeableDrawer, useMediaQuery, IconButton } from '@mui/material';
import ActivityIndex from '../components/ActivityIndex';
import FloatingButton from '../components/FloatingButton';
import AddSession from './AddSession.js';
import StartSession from './StartSession.js';
import AddIcon from '@mui/icons-material/Add';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { useParams } from 'react-router-dom';
import SessionTable from '../components/SessionTable.js';
import useThrottle from '../hooks/useThrottle'; // Custom hook for throttling
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';

const trackSessions = [
    {
        dateTime: '2024-07-03T14:00:00',
        duration: 50,
        focus: 'some notes',
        performance: 1,
        retro: null,
    },
    {
        dateTime: '2024-07-03T11:00:00',
        duration: 50,
        focus: 'some',
        performance: 1,
        retro: 'notes',
    },
    {
        dateTime: '2024-07-03T10:00:00',
        duration: 50,
        focus: null,
        performance: 1,
        retro: null,
    },
];

const TrackDetails = () => {
    const sortedWorkouts = trackSessions.sort((a, b) => new Date(b.dateTime) - new Date(a.dateTime));
    const [targetProgress, setTargetProgress] = useState(150);
    const [openAddSession, setOpenAddSession] = useState(false);
    const [openStartSession, setOpenStartSession] = useState(false);
    const { trackId } = useParams();
    const [description, setDescription] = useState('');
    const sessionTableRef = useRef(null);
    const [tableWidth, setTableWidth] = useState('auto');
    const [title, setTitle] = useState(trackId.replace(/_/g, ' ').replace(/^\w/, c => c.toUpperCase()));
    const [isEditingTitle, setIsEditingTitle] = useState(false);
    const [isEditingDescription, setIsEditingDescription] = useState(false);
    const throttledDescription = useThrottle(description, 500);
    const [openActivityDialog, setOpenActivityDialog] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery('(max-width:600px)');

    const saveChanges = useCallback(() => {
        // Save title and description to the server or local storage
        console.log('Saving changes:', { title, description });
    }, [title, description]);

    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.hidden) {
                saveChanges();
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [saveChanges]);

    useEffect(() => {
        saveChanges();
    }, [throttledDescription, saveChanges]);

    useEffect(() => {
        if (sessionTableRef.current) {
            setTableWidth(sessionTableRef.current.offsetWidth);
        }
    }, []);

    const handleSliderChange = (event, newValue) => {
        setTargetProgress(newValue);
    };

    const buttons = [
        {
            open: openAddSession,
            setOpen: setOpenAddSession,
            title: "Add Session",
            children: <AddSession />,
            icon: <AddIcon />,
            label: "Add Session",
            extended: true
        },
        {
            open: openStartSession,
            setOpen: setOpenStartSession,
            title: "Start Session",
            children: <StartSession />,
            icon: <PlayArrowIcon />,
            label: "Start Session",
            extended: true,
        },
    ];

    const handleTitleClick = () => {
        setIsEditingTitle(true);
    };

    const handleDescriptionClick = () => {
        setIsEditingDescription(true);
    };

    const handleTitleChange = (e) => {
        setTitle(e.target.value);
    };

    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
    };

    const handleBlur = () => {
        setIsEditingTitle(false);
        setIsEditingDescription(false);
        saveChanges();
    };

    return (
        <>
            <Box>
                <Box display="flex" flexDirection="column" alignItems="center">
                    {isEditingTitle ? (
                        <TextField
                            value={title}
                            onChange={handleTitleChange}
                            onBlur={handleBlur}
                            autoFocus
                            sx={{ mt: 4, mb: 2 }}
                        />
                    ) : (
                        <Typography variant="h6" sx={{ mt: 2, mb: 1 }} onClick={handleTitleClick}>
                            {title}
                        </Typography>
                    )}

                    {isEditingDescription ? (
                        <TextField
                            label="Track description"
                            multiline
                            fullWidth
                            value={description}
                            onChange={handleDescriptionChange}
                            onBlur={handleBlur}
                            autoFocus
                            sx={{ mb: 2, width: tableWidth }}
                        />
                    ) : (
                        <Typography
                            variant="body2"
                            textAlign='center'
                            sx={{ mb: 2, width: tableWidth, whiteSpace: 'pre-line' }}
                            color={description === '' ? 'secondary' : 'primary'}
                            onClick={handleDescriptionClick}
                        >
                            {description || 'Click to add a description'}
                        </Typography>
                    )}

                    <Box sx={{ position: 'relative', width: 'fit-content' }}>
                        <ActivityIndex
                            progress={targetProgress}
                            size={200} strokeWidth={50}
                            actionOnClick={() => setOpenActivityDialog(true)}
                        />
                    </Box>
                    <Slider
                        value={targetProgress}
                        onChange={handleSliderChange}
                        aria-labelledby="progress-slider"
                        min={0}
                        max={500}
                        step={0.5}
                        sx={{ width: tableWidth, marginTop: 2, marginBottom: 2 }}
                    />

                    <Box ref={sessionTableRef} sx={{ width: '100%' }}>
                        <SessionTable sessions={sortedWorkouts} />
                    </Box>
                </Box>
            </Box>
            <FloatingButton buttons={buttons} extendOnHover={true} />

            {isMobile ? (
                <SwipeableDrawer
                    anchor="bottom"
                    open={openActivityDialog}
                    onClose={() => setOpenActivityDialog(false)}
                    onOpen={() => setOpenActivityDialog(true)}
                    sx={{
                        '& .MuiPaper-root': {
                            borderTopLeftRadius: 16,
                            borderTopRightRadius: 16,
                            padding: 3,
                            paddingTop: 4,
                        },
                    }}
                >
                    <IconButton
                        aria-label="close"
                        onClick={() => setOpenActivityDialog(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    {/* Add your content here */}
                </SwipeableDrawer>
            ) : (
                <Dialog open={openActivityDialog} onClose={() => setOpenActivityDialog(false)} fullWidth maxWidth="sm">
                    <DialogTitle>
                        Activity Details
                        <IconButton
                            aria-label="close"
                            onClick={() => setOpenActivityDialog(false)}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        {/* Add your content here */}
                    </DialogContent>
                </Dialog>
            )}
        </>
    );
};

export default TrackDetails;