import React, { useState, useRef, useEffect } from 'react';
import { Fab, Dialog, DialogTitle, DialogContent, SwipeableDrawer, IconButton, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@emotion/react';
import { useMediaQuery } from '@mui/material';
import { styled, keyframes } from '@mui/system';

const revealText = keyframes`
  0% {
    clip-path: inset(0 100% 0 0);
  }
  100% {
    clip-path: inset(0 0 0 0);
  }
`;

const AnimatedFabText = styled('span')({
  display: 'inline-block',
  whiteSpace: 'nowrap',
  clipPath: 'inset(0 100% 0 0)',
  animation: `${revealText} 0.3s ease forwards`,
  marginLeft: '8px', // Space between icon and text
});

const FloatingButton = ({ buttons, extendOnHover }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery('(max-width:600px)');
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const fabRefs = useRef([]);
  const [fabWidths, setFabWidths] = useState([]);
  const [fabHeights, setFabHeights] = useState([]);

  useEffect(() => {
    fabRefs.current = fabRefs.current.slice(0, buttons.length);
  }, [buttons.length]);

  useEffect(() => {
    // Calculate the width needed for each button when extended
    if (hoveredIndex !== null && fabRefs.current[hoveredIndex]) {
      const textSpan = fabRefs.current[hoveredIndex].querySelector('span');
      if (textSpan) {
        const fullWidth = textSpan.offsetWidth + 56; // Icon width + padding
        setFabWidths(prevWidths => {
          const updatedWidths = [...prevWidths];
          updatedWidths[hoveredIndex] = fullWidth;
          return updatedWidths;
        });
      }
    }
  }, [hoveredIndex, buttons.length]);

  useEffect(() => {
    const heights = fabRefs.current.map(fab => fab ? fab.offsetHeight : 0);
    setFabHeights(heights);
  }, [buttons.length]);

  return (
    <div style={{ 
      position: 'fixed', 
      bottom: 16, 
      left: 0, 
      width: '100%', 
      display: 'flex', 
      justifyContent: 'center', 
      zIndex: buttons.some(button => button.open) ? 0 : 1 
    }}>
      <Stack direction="row" spacing={2} alignItems={'center'}>
        {buttons.map((button, index) => (
          <React.Fragment key={index}>
            <Fab
              ref={el => fabRefs.current[index] = el}
              variant="extended"
              size="medium"
              color="primary"
              onClick={() => button.setOpen(true)}
              onMouseEnter={() => extendOnHover && setHoveredIndex(index)}
              onMouseLeave={() => extendOnHover && setHoveredIndex(null)}
              sx={{
                zIndex: 1000,
                background: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
                transition: 'width 0.3s, border-radius 0.3s',
                width: extendOnHover && hoveredIndex === index ? `${fabWidths[index] || 40}px` : '40px',
                borderRadius: fabHeights[index] ? `${fabHeights[index] / 2}px` : '20px', // Dynamically set borderRadius
                '&:hover': {
                  background: theme.palette.primary.dark,
                },
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {button.icon}
              <AnimatedFabText
                style={{
                  display: extendOnHover && hoveredIndex === index ? 'inline-block' : 'none',
                }}
              >
                {button.title}
              </AnimatedFabText>
            </Fab>
            {isMobile ? (
              <SwipeableDrawer
                anchor="bottom"
                open={button.open}
                onClose={() => button.setOpen(false)}
                onOpen={() => button.setOpen(true)}
                sx={{
                  '& .MuiPaper-root': {
                    borderTopLeftRadius: 16,
                    borderTopRightRadius: 16,
                    padding: 3,
                    paddingTop: 4,
                  },
                }}
              >
                <IconButton
                  aria-label="close"
                  onClick={() => button.setOpen(false)}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
                {button.children}
              </SwipeableDrawer>
            ) : (
              <Dialog open={button.open} onClose={() => button.setOpen(false)} fullWidth maxWidth="sm">
                <DialogTitle>
                  {button.title}
                  <IconButton
                    aria-label="close"
                    onClick={() => button.setOpen(false)}
                    sx={{
                      position: 'absolute',
                      right: 8,
                      top: 8,
                      color: theme.palette.grey[500],
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </DialogTitle>
                <DialogContent>
                  {button.children}
                </DialogContent>
              </Dialog>
            )}
          </React.Fragment>
        ))}
      </Stack>
    </div>
  );
};

export default FloatingButton;
