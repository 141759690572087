import React, { useState, useEffect, useRef, useCallback } from 'react';
import { TextField, MenuItem, Button, IconButton, Box, Dialog, DialogActions, DialogContent, DialogContentText, Chip, Typography } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useTheme } from '@mui/material/styles';

const CustomSelect = ({ initialOptions = [], label, defaultValue = null, onChange = () => {} }) => {
  const theme = useTheme();
  const inputRef = useRef(null);
  const dropdownRef = useRef(null);
  const wrapperRef = useRef(null);

  const [options, setOptions] = useState(initialOptions);
  const [filteredOptions, setFilteredOptions] = useState(initialOptions);
  const [selectedOption, setSelectedOption] = useState(defaultValue);
  const [inputValue, setInputValue] = useState('');
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [optionToDelete, setOptionToDelete] = useState(null);
  const [optionToEdit, setOptionToEdit] = useState(null);
  const [editValue, setEditValue] = useState('');
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const [isDialogOpen, setIsDialogOpen] = useState(false); // New state to track dialog open status

  const isFirstRender = useRef(true);

  useEffect(() => {
    const filteredOpts = options.filter(option =>
      option.toLowerCase().includes(inputValue.toLowerCase())
    );
    setFilteredOptions(filteredOpts);
    
    // Reset highlighted index when filtered options change
    setHighlightedIndex(-1);
  }, [inputValue, options]);

  useEffect(() => {
    if (isFirstRender.current) {
      setSelectedOption(defaultValue);
      isFirstRender.current = false;
    }
  }, [defaultValue]);

  const handleSelectOption = useCallback((option, keepDropdownVisible = false) => {
    setSelectedOption(option);
    setInputValue('');
    onChange(option);
    if (!keepDropdownVisible) {
      setDropdownVisible(false);
    }
  }, [onChange]);

  const handleCreateOption = useCallback((keepDropdownVisible = false) => {
    if (inputValue && !options.includes(inputValue)) {
      const newOption = inputValue.trim();
      setOptions(prevOptions => [...prevOptions, newOption]);
      setSelectedOption(newOption);
      onChange(newOption);
      setInputValue('');
      if (!keepDropdownVisible) {
        setDropdownVisible(false);
      }
    }
  }, [inputValue, options, onChange]);

  const handleDeleteOption = (option) => {
    setOptions(options.filter(opt => opt !== option));
    setSelectedOption(null);
    onChange(null);
    setOpenConfirmDelete(false);
  };

  const handleClearSelection = useCallback(() => {
    setSelectedOption(null);
    onChange(null);
  }, [onChange]);

  const handleEditOption = (option) => {
    const updatedOptions = options.map(opt => (opt === optionToEdit ? editValue : opt));
    setOptions(updatedOptions);
    if (selectedOption === optionToEdit) {
      setSelectedOption(editValue);
      onChange(editValue);
    }
    setOpenEditDialog(false);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (dropdownVisible) {
        const totalOptions = filteredOptions.length + (inputValue && !options.includes(inputValue) ? 1 : 0);
        
        if (event.key === 'ArrowDown') {
          setHighlightedIndex((prevIndex) => (prevIndex + 1) % totalOptions);
          event.preventDefault();
        } else if (event.key === 'ArrowUp') {
          setHighlightedIndex((prevIndex) => (prevIndex - 1 + totalOptions) % totalOptions);
          event.preventDefault();
        } else if (event.key === 'Enter') {
          if (highlightedIndex >= 0 && highlightedIndex < filteredOptions.length) {
            handleSelectOption(filteredOptions[highlightedIndex], true);
          } else if (highlightedIndex === filteredOptions.length && inputValue && !options.includes(inputValue)) {
            handleCreateOption(true);
          } else if (inputValue && highlightedIndex === -1) {
            handleCreateOption(true);
          }
          event.preventDefault();
        } else if (event.key === 'Tab' || event.key === 'Escape') {
          setDropdownVisible(false);
        } else if ((event.key === 'Backspace' || event.key === 'Delete') && inputValue === '' && selectedOption) {
          handleClearSelection();
        }
      }
    };
  
    const inputElement = inputRef.current;
    inputElement.addEventListener('keydown', handleKeyDown);
    return () => {
      inputElement.removeEventListener('keydown', handleKeyDown);
    };
  }, [dropdownVisible, filteredOptions, highlightedIndex, inputValue, selectedOption, options, handleSelectOption, handleCreateOption, handleClearSelection]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Box
      ref={wrapperRef}
      sx={{ position: 'relative'}}
      onFocus={() => {
        if (!isDialogOpen) { // Only set focus if dialog is not open
          setDropdownVisible(true);
          setIsFocused(true);
          inputRef.current.focus();
        }
      }}
    >
      <TextField
        variant="outlined"
        label={label}
        fullWidth
        focused={!!isFocused}
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 1,
          pointerEvents: 'none',
        }}
        InputLabelProps={{
          shrink: !!(isFocused || selectedOption || inputValue !== ''),
        }}
        InputProps={{
          readOnly: true,
        }}
      />
      <Box
        sx={{
          position: 'relative',
          zIndex: 2,
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          padding: theme.spacing(1.8, 1, 1, 1),
          bottom: '4px',
          top: '0px'
        }}
        onClick={() => inputRef.current.focus()}
      >
        {selectedOption && (
          <Chip
            label={selectedOption}
            onDelete={handleClearSelection}
            sx={{
              marginRight: '4px',
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
              '& .MuiChip-deleteIcon': {
                color: theme.palette.secondary.main,
                '&:hover': {
                  color: theme.palette.primary.contrastText,
                },
              },
            }}
          />
        )}
        <TextField
          variant="standard"
          InputProps={{ disableUnderline: true }}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onFocus={() => {
            if (!dropdownVisible) {
              setDropdownVisible(true);
              setIsFocused(true);
            }
          }}
          onBlur={() => {
            setIsFocused(false);
          }}
          sx={{ flex: 1, marginLeft: selectedOption ? '8px' : '0' }}
          inputRef={inputRef}
        />
      </Box>
      {dropdownVisible && (filteredOptions.length > 0 || inputValue !== '') && (
        <Box
          ref={dropdownRef}
          sx={{
            position: 'absolute',
            top: 'calc(100% + 4px)',
            left: 0,
            right: 0,
            maxHeight: 300,
            overflowY: 'auto',
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: theme.spacing(0.5),
            backgroundColor: theme.palette.background.paper,
            zIndex: 1300,
            padding: theme.spacing(1, 0, 0.5, 0)
          }}
        >
          {filteredOptions.map((option, index) => (
            <MenuItem
              key={option}
              onClick={() => {
                handleSelectOption(option);
                inputRef.current.blur();
              }}
              dense={true}
              selected={index === highlightedIndex}
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                <Chip label={option} size="small" />
                <Box>
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      setOptionToEdit(option);
                      setEditValue(option);
                      setOpenEditDialog(true);
                      setIsDialogOpen(true); // Set dialog open status
                    }}
                  >
                    <MoreHorizIcon fontSize="small" />
                  </IconButton>
                </Box>
              </Box>
            </MenuItem>
          ))}
          {!options.map(opt => opt.toLowerCase()).includes(inputValue.toLowerCase()) && inputValue && (
            <MenuItem
              onClick={(e) => {
                e.preventDefault();
                handleCreateOption(true);
                inputRef.current.blur();
              }}
              selected={highlightedIndex === filteredOptions.length}
            >
              <Typography variant="body1" sx={{ mr: 1 }}>Create</Typography>
              <Chip label={inputValue} size="small" />
            </MenuItem>
          )}
        </Box>
      )}
      <Dialog
        open={openConfirmDelete}
        onClose={() => setOpenConfirmDelete(false)}
        PaperProps={{
          sx: { 
            zIndex: 1400,
            backgroundColor: theme.palette.background.default
          }
        }}
      >
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete "{optionToDelete}"?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button size="small" color="secondary" onClick={() => setOpenConfirmDelete(false)}>Cancel</Button>
          <Button size="small" onClick={() => handleDeleteOption(optionToDelete)} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openEditDialog}
        onClose={() => {
          setOpenEditDialog(false);
          setIsDialogOpen(false); // Reset dialog open status
        }}
        PaperProps={{
          sx: { 
            zIndex: 1400,
            backgroundColor: theme.palette.background.default
          }
        }}
      >
        <DialogContent sx={{ width: theme.spacing(30) }}>
          <TextField
            autoFocus
            margin="dense"
            label="Option name"
            type="text"
            fullWidth
            value={editValue}
            onChange={(e) => setEditValue(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button size="small" color="secondary" onClick={() => {
            setOpenEditDialog(false);
            setIsDialogOpen(false); // Reset dialog open status
          }}>Cancel</Button>
          <Button size="small" color="error" onClick={() => {
            setOpenEditDialog(false);
            setOptionToDelete(optionToEdit);
            setOpenConfirmDelete(true);
            setIsDialogOpen(false); // Reset dialog open status
          }}>
            Delete
          </Button>
          <Button size="small" sx={{minWidth: 'auto'}} onClick={() => {
            if (editValue.trim() === '') {
              setOpenEditDialog(false);
              setOptionToDelete(optionToEdit);
              setOpenConfirmDelete(true);
              setIsDialogOpen(false); // Reset dialog open status
            } else {
              handleEditOption(optionToEdit);
              setIsDialogOpen(false); // Reset dialog open status
            }
          }} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CustomSelect;