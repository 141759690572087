import React, { useState, useEffect, useRef } from 'react';
import { TextField, Button, Stack, Typography } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CustomSelect from '../components/CustomSelect';



// Function to get the current date and time rounded to the nearest half hour
const getCurrentDateTime = () => {
    const now = new Date();
    now.setMinutes(now.getMinutes() >= 30 ? 30 : 0, 0, 0);
    return now;
};

function StartSession() {
    const [formData, setFormData] = useState({
        date: getCurrentDateTime(),
        endTime: '',
        duration: '',
        focus: '',
        performance: '',
        fun: '',
        retro: '',
        type: ''
    });
    const [step, setStep] = useState(1);
    const [timer, setTimer] = useState(0);
    const [isRunning, setIsRunning] = useState(false);
    const [elapsedTime, setElapsedTime] = useState(0);
    const timerRef = useRef(null);
    const [previousIsRunning, setPreviousIsRunning] = useState(false);

    useEffect(() => {
        let startTime;
        const updateTimer = (timestamp) => {
            if (!startTime) startTime = timestamp;
            const elapsed = timestamp - startTime + elapsedTime;
            setTimer(elapsed);
            if (isRunning) {
                timerRef.current = requestAnimationFrame(updateTimer);
            }
        };

        if (isRunning) {
            timerRef.current = requestAnimationFrame(updateTimer);
        } else {
            cancelAnimationFrame(timerRef.current);
        }

        return () => cancelAnimationFrame(timerRef.current);
    }, [isRunning, elapsedTime]);

    const handlePauseResume = () => {
        if (isRunning) {
            setElapsedTime(timer);
        }
        setIsRunning(!isRunning);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleDateChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const durationInMinutes = Math.max(1, Math.ceil(timer / 60000));
        setFormData({
            ...formData,
            duration: durationInMinutes
        });
        // Handle form submission
    };

    const formatTime = (milliseconds) => {
        const minutes = Math.floor(milliseconds / 60000);
        const seconds = Math.floor((milliseconds % 60000) / 1000);
        const millis = Math.floor(milliseconds % 1000);
        return {
            minutes,
            seconds,
            millis
        };
    };

    const handleStepChange = (newStep) => {
        if (newStep === 2) {
            setElapsedTime(timer);
            setPreviousIsRunning(isRunning);
            setIsRunning(false);
        } else if (newStep === 1) {
            setIsRunning(previousIsRunning);
        }
        setStep(newStep);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <form onSubmit={handleSubmit}>
                <Stack gap={2}>
                    {step === 1 && (
                        <>
                        <Stack direction={'column'} alignItems={'center'}>
                                {(() => {
                                    const { minutes, seconds, millis } = formatTime(timer);
                                    return (
                                        <Button onClick={handlePauseResume} sx={{px: 2}}>
                                            <Stack direction={'column'} alignItems={'baseline'} sx={{position: 'relative', top: '12px'}}>
                                                <Typography variant="body" color={'secondary'} sx={{ fontStyle: 'italic'}}>Session timer:</Typography>
                                                <Stack direction={'row'} alignItems={'baseline'} sx={{ fontStyle: 'italic', fontFamily: 'monospace', position: 'relative', top: '-12px' }}>
                                                    <Typography variant="h1" sx={{fontWeight: 'bold'}}>{`${minutes.toString().padStart(2, '0')}'${seconds.toString().padStart(2, '0')}"`}</Typography>
                                                    <Typography variant="body" >{millis.toString().padStart(3, '0')}</Typography>
                                                </Stack>
                                            </Stack>
                                            
                                        </Button>
                                        
                                    );
                                })()}
                        </Stack>
                            
                            <CustomSelect
                                initialOptions={[]}
                                label={'Type'}
                            />
                            <TextField
                                label="Focus"
                                onChange={handleChange}
                                fullWidth
                                multiline
                                rows={4}
                            />
                            <TextField
                                label="Goal duration (m)"
                                type="number"
                                name="goalDuration"
                                onChange={handleChange}
                                fullWidth
                            />
                            <Button onClick={() => handleStepChange(2)} variant="contained" color="primary">
                                Finish Session
                            </Button>
                        </>
                    )}
                    {step === 2 && (
                        <>
                            <Button onClick={() => handleStepChange(1)}>←</Button>
                            <DateTimePicker
                                label="Date and time"
                                value={formData.date}
                                onChange={(newValue) => handleDateChange('date', newValue)}
                                format="MMM dd, yyyy | HH:mm"
                                viewRenderers={window.innerWidth > 768 ? {
                                hours: null,
                                minutes: null,
                                seconds: null,
                            } : {}}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                    />
                                )}
                                ampm={false}
                            />
                            <TextField
                                label="Duration (m)"
                                type="number"
                                name="duration"
                                value={Math.max(1, Math.ceil(timer / 60000))}
                                onChange={handleChange}
                                fullWidth
                            />
                            <CustomSelect
                                initialOptions={[]}
                                label={'Type'}
                                name="type"
                                value={formData.type}
                                onChange={handleChange}
                                defaultValue={formData.type}
                            />
                            <CustomSelect
                                initialOptions={['awful', 'weak', 'medium', 'high', 'outstanding']}
                                label={'Performance'}
                                name="performance"
                                value={formData.performance}
                                onChange={handleChange}
                            />
                            <CustomSelect
                                initialOptions={['boring', 'dull', 'average', 'fun', 'exciting']}
                                label={'Fun'}
                                name="fun"
                                value={formData.fun}
                                onChange={handleChange}
                            />
                            <TextField
                                label="Retro"
                                name="retro"
                                value={formData.retro}
                                onChange={handleChange}
                                fullWidth
                                multiline
                                rows={4}
                            />
                            <Button type="submit" variant="contained" color="primary">
                                Submit
                            </Button>
                        </>
                    )}
                </Stack>
            </form>
        </LocalizationProvider>
    );
}

export default StartSession;