import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeContextProvider } from './ThemeContext';
import WorkoutList from './pages/WorkoutList';
import WorkoutDetail from './pages/WorkoutDetail';
import Workouts from './pages/Workouts';
import './index.css';
import App from './App';
import { useTheme } from '@mui/material/styles';
import PreToJSON from './pages/PreToJSON';
import Tracks from './pages/Tracks';
import TrackDetails from './pages/TrackDetails';

const root = ReactDOM.createRoot(document.getElementById('root'));

const ThemedApp = () => {
  const theme = useTheme();

  React.useEffect(() => {
    document.body.style.setProperty('--background-color', theme.palette.background.default);
    document.body.style.setProperty('--text-color', theme.palette.text.primary);
  }, [theme]);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<Workouts />} />
          <Route path="list" element={<WorkoutList />} />
          <Route path="templates" element={<div>Templates Page</div>} />
          <Route path="insights_and_analytics" element={<div>Insights and Analytics Page</div>} />
          <Route path="list/:id" element={<WorkoutDetail />} />
          <Route path="prototype" element={<PreToJSON />} />
          <Route path="tracks" element={<Tracks />} />
          <Route path="/tracks/:trackId" element={<TrackDetails />} />
        </Route>
      </Routes>
    </Router>
  );
};

root.render(
  <React.StrictMode>
    <ThemeContextProvider>
      <ThemedApp />
    </ThemeContextProvider>
  </React.StrictMode>
);